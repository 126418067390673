import Head from 'next/head';

const Meta = (): JSX.Element => (
  <Head>
    <meta
      key="meta:description"
      name="description"
      content="Teach empathy and understanding in Australian schools."
    />
    <meta key="meta:image" property="og:image" content="TODO" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
  </Head>
);

export default Meta;
