import { firebaseClient } from 'core/firebase/FirebaseClient';
import { useIdleTimeout } from 'hooks/useIdleTimer';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { API, PAGE } from '../Constants';

const AuthenticationContext = React.createContext<{
  isAuthenticating: boolean;
  user: firebaseClient.User | null;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}>({
  isAuthenticating: true,
  user: null,
  signIn: () => Promise.reject(),
  signOut: () => Promise.reject(),
});

export const AuthenticationProvider = ({ children }: any): JSX.Element => {
  const [user, setUser] = useState<firebaseClient.User | null>(null);
  const router = useRouter();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(10);
  const timer = useRef<NodeJS.Timer>();

  const handleIdle = () => {
    if (user) {
      setModalIsOpen(true);
    }
  };

  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 30 * 60 });

  const stay = () => {
    if (timer.current) {
      clearInterval(timer.current);
      idleTimer.reset();
      setModalIsOpen(false);
      setCountdown(10);
    }
  };

  const signIn = async (email: string, password: string) => {
    await firebaseClient.auth().signInWithEmailAndPassword(email, password);
  };

  const signOut = async () => {
    await firebaseClient.auth().signOut();
    setUser(null);
    destroyCookie({}, 'token');
    destroyCookie({}, 'signedIn');
  };

  useEffect(() => {
    return firebaseClient.auth().onIdTokenChanged(async (user) => {
      destroyCookie({}, 'token');
      if (user) {
        const lastSignInTime = user?.metadata?.lastSignInTime
          ? new Date(user.metadata.lastSignInTime).getTime()
          : 0;
        const currentTime = new Date().getTime();
        const dayInMilliseconds = 86400000; // Day in milliseconds
        const timeDifference = currentTime - lastSignInTime;
        if (timeDifference >= dayInMilliseconds) {
          await signOut();
          router.push(PAGE.LOGIN);
        } else {
          const token = await user.getIdToken();
          const seconds = 86400; // Seconds in a day
          const milliseconds = seconds * 1000; // Milliseconds in a day
          const expiry = new Date(Date.now() + milliseconds).toISOString();

          setUser(user);
          setCookie({}, 'token', token, {
            path: '/',
            sameSite: 'Strict',
            secure: process.env.NODE_ENV === 'production',
            expires: new Date(expiry),
          });

          const { signedIn } = parseCookies();

          if (signedIn !== 'true') {
            setCookie({}, 'signedIn', 'true', {
              path: '/',
              sameSite: 'Strict',
              secure: process.env.NODE_ENV === 'production',
              expires: new Date(expiry),
            });

            const requestOptions = {
              method: 'PATCH',
              headers: { 'Content-Type': 'application/json' },
            };
            await fetch(API.ME, requestOptions);
          }
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebaseClient.auth().currentUser;
      if (user) {
        await user.getIdToken(true);
      }
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    if (modalIsOpen) {
      timer.current = setInterval(() => {
        setCountdown((val) => (val = val - 1));
      }, 1000);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (countdown < 0) {
      (async () => {
        if (timer.current) {
          clearInterval(timer.current);
          idleTimer.reset();
          setModalIsOpen(false);
          setCountdown(10);
          await signOut();
          router.push(PAGE.LOGIN);
        }
      })();
    }
  }, [countdown]);

  return (
    <AuthenticationContext.Provider value={{ isAuthenticating: loading, user, signIn, signOut }}>
      {children}
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Intervention"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="row">
          <div className="tal">
            <h2 className="h3 xb">You&apos;ve been idle for 30 minutes.</h2>
            <div className="btn-group">
              <button type="button" className="btn cancel" onClick={signOut}>
                Log out
              </button>
              <button type="button" className="btn blue-all-in" onClick={stay}>
                Stay logged in ({countdown})
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

export const useRequireAuthentication = () => {
  const router = useRouter();
  const { isAuthenticating, user } = useAuthentication();
  useEffect(() => {
    if (!isAuthenticating && !user) {
      router.push(PAGE.LOGIN);
    }
  }, [isAuthenticating, user]);
  return user;
};
