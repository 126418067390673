import Script from 'next/script';
import { GTM_ID } from './GoogleTagManager.config';

const GTM_AUTH = process.env['NX_GTM_AUTH'];
const GTM_PREVIEW = process.env['NX_GTM_PREVIEW'];

export function GoogleTagManager() {
  if (!GTM_ID) {
    return null;
  }

  const gtmAuth =
    GTM_AUTH && GTM_PREVIEW
      ? `&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x`
      : '';
  const gtmAuthExtended = gtmAuth ? `+ '${gtmAuth}'` : '';

  return (
    <>
      <Script
        strategy="beforeInteractive"
        id="googleTagManager"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl${gtmAuthExtended};f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${GTM_ID}');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe
        src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}${gtmAuth}"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>`,
        }}
      />
    </>
  );
}

export default GoogleTagManager;
