import { IPublicWebsiteNavigation } from 'core/strapi/types';
import Link from 'next/link';
import { useState } from 'react';

type PublicProps = {
  navigation: IPublicWebsiteNavigation;
  showAction?: boolean;
};

const Public = ({ navigation, showAction = true }: PublicProps): JSX.Element => {
  const [menuIsOpen, toggleMenuIsOpen] = useState(false);

  return (
    <header className={`header ${menuIsOpen ? 'menu-opened' : ''}`}>
      <div className="container">
        <button
          type="button"
          className="burger-container"
          onClick={() => {
            toggleMenuIsOpen((menuIsOpen) => !menuIsOpen);
          }}
          aria-label="toggle navigation"
        >
          <div className="burger">
            <div className="bar topBar"></div>
            <div className="bar midBar"></div>
            <div className="bar btmBar"></div>
          </div>
        </button>
        <nav className="header-nav">
          <div className="left">
            <Link href="/">
              <a>
                <img className="logo" src="/images/logo.svg" alt="Click Against Hate Logo" />
              </a>
            </Link>
            <ul className="nav">
              {navigation &&
                navigation.Link.map((link) => {
                  return (
                    <li key={link.id}>
                      <Link href={`/${link.slug}`}>
                        <a className={link.cssClass}>{link.title}</a>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="right">
              {showAction && (<ul className="nav">
              <li className="before-button-wrap">
                <Link href="/account/login">
                  <a>Sign In</a>
                </Link>
              </li>
              <li className="button-wrap">
                <Link href="/account/register">
                  <a className="btn round">Get Started</a>
                </Link>
              </li>
            </ul>) }
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Public;
