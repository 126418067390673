import Layout from '@/components/layout/Layout';
import React from 'react';
import { ReactNode } from 'react-markdown/lib/react-markdown';

interface IProps {
  hasError?: boolean;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { hasError: false };
  }
  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Layout navigationPublic={{ Link: [] }} showAction={false}>
          <main>
            <section className="hero-cta">
              <div className="container">
                <div className="copy col-8">
                  <h1 className="primary">Uh oh, something has gone wrong :(</h1>
                  <p className="subtext">
                    Click Against Hate appears to be expierencing technical difficulties. Try
                    refreshing your browser and if that doesn&lsquo;t work please come back later.
                  </p>
                </div>
              </div>
              <div className="bottom-graphic"></div>
            </section>
          </main>
        </Layout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
