import ErrorBoundary from '@/components/errorBoundary/ErrorBoundary';
import MarkerIO from '@/components/markerIO';
import GoogleTagManager from '@/components/utility/GoogleTagManager/GoogleTagManager';
import { AuthenticationProvider } from 'core/authentication/AuthenticationProvider';
import useH1Focus from 'hooks/useH1Focus';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import '../styles/styles.scss';

export default function App({ Component, pageProps }: AppProps): ReactElement {
  const router = useRouter();
  useH1Focus(router.pathname);

  return (
    <>
      <GoogleTagManager />
      <AuthenticationProvider>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </AuthenticationProvider>

      {process.env.NEXT_PUBLIC_ENABLE_MARKER_IO === 'true' && (
        <MarkerIO markerIODestination="645dbe5f19452006660e62f8" />
      )}
    </>
  );
}
