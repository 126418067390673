import styles from './Styles.module.scss';

const Loading = ({ small = false }: { small?: boolean }): JSX.Element => {
  return (
    <div className={`${styles.container} ${small ? styles.loadingSmall : ''}`}>
      <img className={styles.loader} src="/images/loading-oval.svg" alt="loading" />
    </div>
  );
};

export default Loading;
