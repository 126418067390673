import { IFooterNavigation } from 'core/strapi/types';
import Link from 'next/link';

type FooterProps = {
  navigation: IFooterNavigation;
};

const Footer = ({ navigation }: FooterProps): JSX.Element => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <Link href="/">
              <a>
                <img className="logo" src="/images/logo.svg" alt="Click Against Hate Logo" />
              </a>
            </Link>
            <p className="byline">
              We’re helping create a world where ignorance and discrimination have no place
            </p>
          </div>

          {navigation &&
            navigation.linkColumn.map((column, i) => {
              return (
                <div className="col-2" key={i}>
                  <ul>
                    {column.link.map((link, i) => {
                      return (
                        <li key={i}>
                          <Link href={`/${String(link.slug).replace('/', '')}`}>
                            <a className={link.cssClass}>{link.title}</a>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>

        <div className="disclaimer small">
          &copy; {new Date().getFullYear()} Click Against Hate.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
