import { useAuthentication } from 'core/authentication/AuthenticationProvider';
import { PAGE } from 'core/Constants';
import { IAuthenticatedWebsiteNavigation } from 'core/strapi/types/authenticated-website-navigation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

type AuthenticatedProps = {
  navigation: IAuthenticatedWebsiteNavigation;
  showAction?: boolean;
};

const Authenticated = ({ navigation, showAction = true }: AuthenticatedProps): JSX.Element => {
  const router = useRouter();
  const [menuIsOpen, toggleMenuIsOpen] = useState(false);
  const { signOut } = useAuthentication();
  const onClickSignOut = async () => {
    await signOut();
    router.push(PAGE.LOGIN);
  };
  return (
    <header className={`header ${menuIsOpen ? 'menu-opened' : ''}`}>
      <div className="container">
        <button
          type="button"
          className="burger-container"
          onClick={() => {
            toggleMenuIsOpen((menuIsOpen) => !menuIsOpen);
          }}
          aria-label="toggle navigation"
        >
          <div className="burger">
            <div className="bar topBar"></div>
            <div className="bar midBar"></div>
            <div className="bar btmBar"></div>
          </div>
        </button>
        <nav className="header-nav">
          <div className="left">
            <Link href="/">
              <a>
                <img className="logo" src="/images/logo.svg" alt="Click Against Hate Logo" />
              </a>
            </Link>
            <ul className="nav">
              {navigation &&
                navigation.Link.map((link) => {
                  return (
                    <li key={link.id}>
                      <Link href={`/${link.slug}`}>
                        <a className={link.cssClass}>{link.title}</a>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="right">
            {showAction && (
              <ul className="nav">
                <li>
                  <Link href="/account">
                    <a>Account</a>
                  </Link>
                </li>
                <li>
                  <button type="button" onClick={onClickSignOut}>
                    Log out
                  </button>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Authenticated;
