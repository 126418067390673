import { useAuthentication } from 'core/authentication/AuthenticationProvider';
import {
  IAuthenticatedWebsiteNavigation,
  IFooterNavigation,
  IPublicWebsiteNavigation,
} from 'core/strapi/types';
import { ReactNode } from 'react';
import Footer from '../footer/Footer';
import Loading from '../loading/Loading';
import Meta from '../meta/Meta';
import Authenticated from '../navigation/authenticated/Authenticated';
import Public from '../navigation/public/Public';

type LayoutProps = {
  navigationPublic?: IPublicWebsiteNavigation;
  navigationAuthenticated?: IAuthenticatedWebsiteNavigation;
  navigationFooter?: IFooterNavigation;
  showAction?: boolean;
  children?: ReactNode;
};

const Layout = ({
  navigationPublic,
  navigationAuthenticated,
  navigationFooter,
  showAction = true,
  children,
}: LayoutProps): JSX.Element => {
  const { isAuthenticating, user } = useAuthentication();
  if (isAuthenticating) {
    return <Loading></Loading>;
  } else {
    return (
      <>
        <Meta />
        <main>
          {user && navigationAuthenticated && (
            <Authenticated
              showAction={showAction}
              navigation={navigationAuthenticated}
            ></Authenticated>
          )}
          {!user && navigationPublic && (
            <Public showAction={showAction} navigation={navigationPublic}></Public>
          )}
          {children}
          {navigationFooter && <Footer navigation={navigationFooter}></Footer>}
        </main>
      </>
    );
  }
};

export default Layout;
